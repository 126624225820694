import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import RoutePathComponent from "@app/components/common/route-path.component";
import {PathInterface} from "@app/interfaces/interfaces";
import {ContactUs, ContactUsMessage, ContactUsRequest} from "@app/api/home.api";
import {InputComponent} from "@app/components/common/input/input.component";
import validator from "validator";
import {TextArea2Component} from "@app/components/common/text-area-2/text-area-2.component";
import {toast} from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const AboutUsPage: React.FC = () => {
    const {t} = useTranslation()
    const [setting, setSetting] = useState<any>(null)
    const routePaths: PathInterface[] = [
        {path: "/", title: t("home")},
        {path: "#", title: t('contact-us')},
    ]

    useEffect(() => {
        ContactUs().then(result => {
            setSetting(result.data)
        })
    }, [])


    const [fullName, setFullName] = useState<string>('')
    const [fullNameError, setFullNameError] = useState<string>('')
    const handleSetFullName = (target: any) => {
        setFullNameError('')
        if (validator.isEmpty(target.target.value)) {
            setFullNameError(t('full-name-required'))
        }
        setFullName(target.target.value)
    }

    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')
    const handleSetEmail = (target: any) => {
        setEmailError('')
        if (validator.isEmpty(target.target.value)) {
            setEmailError(t('email-required'))
        } else {
            if (!validator.isEmail(target.target.value)) {
                setEmailError(t('email-invalid'))
            }
        }
        setEmail(target.target.value)
    }

    const [mobile, setMobile] = useState<string>('')
    const [mobileError, setMobileError] = useState<string>('')
    const handleSetMobile = (target: any) => {
        setMobileError('')
        setMobile(target.target.value)
    }

    const [description, setDescription] = useState<string>('')
    const [descriptionError, setDescriptionError] = useState<string>('')
    const handleSetDescription = (target: any) => {
        setFullNameError('')
        if (validator.isEmpty(target.target.value)) {
            setDescriptionError(t('message-required'))
        }
        setDescription(target.target.value)
    }

    const [recaptchaValue, setRecaptchaValue] = useState<string | null>('')
    function ReCAPTCHARecaptcha(value: string | null) {
        setRecaptchaValue(value)
    }

    const [loading, setLoading] = useState<boolean>(false)
    const handelSendMessage = () => {
        setEmailError('')
        setFullNameError('')
        setDescriptionError('')
        let goOn = true

        if (validator.isEmpty(email)) {
            setEmailError(t('email-required'))
            goOn = false
        } else {
            if (!validator.isEmail(email)) {
                setEmailError(t('email-invalid'))
                goOn = false
            }
        }

        if (validator.isEmpty(fullName)) {
            setFullNameError(t('full-name-required'))
            goOn = false
        }

        if (validator.isEmpty(description)) {
            setDescriptionError(t('message-required'))
            goOn = false
        }

        if (goOn) {
            setLoading(true)
            const request: ContactUsRequest = {
                fullName: fullName,
                email: email,
                mobile: mobile,
                message: description,

            }

            ContactUsMessage(request).then(result => {
                setLoading(false)

                if (result.success) {
                    toast.success('send-contact-us-message-done')

                    setFullName('')
                    setEmail('')
                    setMobile('')
                    setDescription('')
                } else {
                    toast.error('send-contact-us-message-failed')
                }
            }).catch(error => {
                setLoading(false)
                toast.error('send-contact-us-message-failed')
            })
        }
    }

    return (
        <S.LayoutPage>
            <div className={'box-route'}>
                <RoutePathComponent paths={routePaths}/>
            </div>

            <div className={'header'}>
                <p>{t('contact-us')}</p>
                <p className={'description'}>{setting ? setting.settings.filter((item: {
                    name: string
                }) => item.name === 'message')[0].value : ''}</p>
            </div>

            <div className={'box'}>
                <div className={'row'}>
                    <div className={'col-1'}>
                        <p style={{marginBottom: '40px'}}>{setting ? setting.settings.filter((item: {
                            name: string
                        }) => item.name === 'description')[0].value : ''}</p>

                        <div className={'clear'}/>

                        <p>{t('email-address')}</p>
                        <strong>{setting ? setting.settings.filter((item: {
                            name: string
                        }) => item.name === 'email')[0].value : 'support@greenia.io'}</strong>

                        <div className={'clear'}/>

                        <p>{t('phone-number')}</p>
                        <strong>{setting ? setting.settings.filter((item: {
                            name: string
                        }) => item.name === 'phone')[0].value : '+49 174 487 75 66'}</strong>


                    </div>
                    <div className={'col-2'}>
                        <h4>{t('contact-us-form')}</h4>

                        <InputComponent error={fullNameError} value={fullName} onChange={handleSetFullName}
                                        type={'text'}
                                        placeholder={'full-name'}/>

                        <InputComponent error={emailError} value={email} onChange={handleSetEmail} type={'email'}
                                        placeholder={'email-address'}/>

                        <InputComponent error={mobileError} value={mobile} onChange={handleSetMobile} type={'tel'}
                                        placeholder={'phone-number'}/>

                        <TextArea2Component error={descriptionError} value={description} onChange={handleSetDescription}
                                            type={'text'} style={{height: '200px'}}
                                            placeholder={'message'}/>

                        {/* @ts-ignore */}
                        <ReCAPTCHA
                            sitekey="6Le8_fcoAAAAAEmIF6EO2EjKvNaAB5GstOQNnByg"
                            onChange={ReCAPTCHARecaptcha}/>

                        <S.SubmitButton type="primary" htmlType="button"
                                        loading={loading} onClick={handelSendMessage}>
                            {t('send-message')}
                        </S.SubmitButton>
                    </div>
                </div>
            </div>
        </S.LayoutPage>
    )
}

export default AboutUsPage;
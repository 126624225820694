import React, { useEffect, useState } from 'react';
import * as S from './styles.style';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserIcon from '@app/assets/images/svgs/user.svg';
import { readToken } from '@app/utils/local-storage.service';
import AuthComponent from '@app/components/auth/mobile/auth.component';
import Modal from 'react-modal';
import { ArrowSVG } from '@app/assets/images/svgs/arrow.svg';
import { CategoryDomain } from '@app/domains/category.domain';
import { BrandDomain } from '@app/domains/brand.domain';
import { GetCategories } from '@app/api/category.api';
import { GetBrands } from '@app/api/brand.api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    borderRadius: '10px',
    zIndex: '1000',
    padding: '0px',
    border: '1px solid var(--black)',
  },
  overlay: {
    zIndex: 1000,
    background: 'argb(0,0,0,0.7)',
  },
};

const SidebarLayout: React.FC<{ showMenu: boolean }> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openAuthModal, setOpenAuthModal] = useState<boolean>(false);
  const [openProductsMenu, setOpenProductsMenu] = useState<boolean>(false);
  const [openBrandMenu, setOpenBrandMenu] = useState<boolean>(false);
  const [selectedProductLevel1, setSelectedProductLevel1] = useState<CategoryDomain>();
  const [selectedProductLevel2, setSelectedProductLevel2] = useState<CategoryDomain>();

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.height = props.showMenu ? '100vh' : 'auto';
      body.style.overflowY = props.showMenu ? 'hidden' : 'auto';
    }

    return () => {
      if (body) {
        body.style.height = 'auto';
        body.style.overflowY = 'auto';
      }
    };
  }, [props.showMenu]);

  const handleOpenAuthModal = () => {
    if (readToken() === '') {
      setOpenAuthModal(true);
    } else {
      navigate('/user-account');
    }
  };

  const [categories, setCategories] = useState<Array<CategoryDomain>>([]);
  const [brands, setBrands] = useState<Array<BrandDomain>>([]);
  useEffect(() => {
    GetCategories().then((result) => {
      setCategories(result.data);
    });
    GetBrands().then((result) => {
      setBrands(result.data);
    });
  }, []);

  const handleOpenProduct = () => {
    setOpenProductsMenu(!openProductsMenu);
  };

  const handleOpenSubMenuLevel1 = (category: CategoryDomain) => {
    if (category.id !== selectedProductLevel1?.id) setSelectedProductLevel1(category);
    else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedProductLevel1(null);
    }
  };

  const handleOpenSubMenuLevel2 = (category: CategoryDomain) => {
    if (category.id !== selectedProductLevel2?.id) setSelectedProductLevel2(category);
    else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedProductLevel2(null);
    }
  };

  const handleCloseMenu = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setSelectedProductLevel1(null);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setSelectedProductLevel2(null);
    setOpenProductsMenu(false);
    props.showMenu = false;
  };

  const handleOpenBrand = () => {
    setOpenBrandMenu(!openBrandMenu);
  };

  return (
    <S.Sidebar className={props.showMenu ? 'show' : ''}>
      <a className={'authentication'} onClick={handleOpenAuthModal}>
        <img src={UserIcon} />
        {readToken() === '' ? <span>{t('login-register')}</span> : <span>{t('user-account')}</span>}
      </a>

      <ul>
        <li>
          <a className={'products'} onClick={handleOpenProduct}>
            <span>{t('products')}</span>
            <b style={{ rotate: openProductsMenu ? '180deg' : '0deg' }}>
              <ArrowSVG />
            </b>
          </a>

          <div className={'sub-menu'} style={{ display: openProductsMenu ? 'block' : 'none' }}>
            {categories.map((category, index) => (
              <>
                <a className={'products'} onClick={() => handleOpenSubMenuLevel1(category)} key={index}>
                  <span>{category.title}</span>
                  <b style={{ rotate: selectedProductLevel1?.id === category.id ? '180deg' : '0deg' }}>
                    <ArrowSVG />
                  </b>
                </a>

                <div
                  className={'sub-menu'}
                  style={{ display: selectedProductLevel1?.id === category.id ? 'block' : 'none' }}
                >
                  {category.categories.map((_category, index) => (
                    <>
                      <a className={'products'} onClick={() => handleOpenSubMenuLevel2(_category)}>
                        <span>{_category.title}</span>
                        <b style={{ rotate: selectedProductLevel2?.id === _category.id ? '180deg' : '0deg' }}>
                          <ArrowSVG />
                        </b>
                      </a>

                      <div
                        className={'sub-menu'}
                        style={{ display: selectedProductLevel2?.id === _category.id ? 'block' : 'none' }}
                      >
                        {_category.categories.map((__category, index) => (
                          <Link
                            onClick={handleCloseMenu}
                            className={'products'}
                            to={'/product-category/' + __category.slug}
                            key={index}
                            state={__category}
                          >
                            <span>{__category.title}</span>
                          </Link>
                        ))}
                      </div>
                    </>
                  ))}
                </div>
              </>
            ))}
          </div>
        </li>
        <li>
          <a className={'products'} onClick={handleOpenBrand}>
            <span>{t('brands')}</span>
            <b style={{ rotate: openBrandMenu ? '180deg' : '0deg' }}>
              <ArrowSVG />
            </b>
          </a>
          <div className={'sub-menu'} style={{ display: openBrandMenu ? 'block' : 'none' }}>
            {brands.map((brand, index) => (
              <Link
                onClick={handleCloseMenu}
                className={'products'}
                to={'/brands/' + brand.slug}
                key={index}
                state={brand}
              >
                <span>{brand.title}</span>
              </Link>
            ))}
          </div>
        </li>
        <li>
          <Link to={'/how-to-buy'} className={'products'} onClick={handleCloseMenu}>
            <a>
              <span>{t('how-to-buy')} </span>
            </a>
          </Link>
        </li>
        <li>
          <Link to={'/about-us'} className={'products'} onClick={handleCloseMenu}>
            <a>
              <span>{t('about-us')}</span>
            </a>
          </Link>
        </li>
        <li>
          <Link to={'/cart'} className={'products'} onClick={handleCloseMenu}>
            <a>
              <span>{t('cart')}</span>
            </a>
          </Link>
        </li>
      </ul>

      {/* @ts-ignore */}
      <Modal
        isOpen={openAuthModal}
        onRequestClose={() => setOpenAuthModal(!openAuthModal)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AuthComponent />
      </Modal>
    </S.Sidebar>
  );
};

export default SidebarLayout;

import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import MainHeaderLayout from "@app/components/layouts/home/header/main-navigation.layout";
import FooterLayout from "@app/components/layouts/home/footer/footer.layout";
import * as S from './style.styles';

const HomeLayout: React.FC = () => {
    const [showScrollButton, setShowScrollButton] = useState(false);

    // Toggle visibility of the "Go to Top" button based on scroll position
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setShowScrollButton(scrollTop > 500);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Scroll to top function
    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 500,
            smooth: true,
        });
    };

    return (
        <S.LayoutMaster>
            <S.LayoutMain>
                <MainHeaderLayout />
                <Outlet />
                <FooterLayout />
                {showScrollButton && (
                    <S.ScrollToTopButton onClick={scrollToTop}>
                        ↑
                    </S.ScrollToTopButton>
                )}
            </S.LayoutMain>
        </S.LayoutMaster>
    );
};

export default HomeLayout;

import {NotificationType} from '@app/components/common/notification/notification.component';

export const camelize = (string: string): string => {
    return string
        .split(' ')
        .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
        .join('');
};

export const getCurrencyPrice = (
    price: number | undefined,
    currency: string | undefined,
  ): string | React.ReactNode => {
    if (!price || !currency) {
      return 'N/A';
    }
  
    // Format the price with commas as thousand separators
    const formattedPrice = (price * 1).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  
    switch (currency) {
      case 'Euro':
        return `€ ${formattedPrice}`;
      default:
        return `${formattedPrice} ${currency}`;
    }
  };
  

type MarkArea = {
    xAxis: number;
};

export const getTotalPrice = (
    price: number | undefined,
    currency: string | undefined,
    quantity: number
  ): string | React.ReactNode => {
    if (price === undefined || currency === undefined) {
      return 'N/A';
    }
  
    const totalPrice = price * quantity;
  
    switch (currency) {
      case 'Euro': {
        return `€ ${totalPrice.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
      }
      default: {
        return `${totalPrice.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} ${currency}`;
      }
    }
  };
  

export const hexToRGB = (hex: string): string => {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return `${r}, ${g}, ${b}`;
};

export const shadeColor = (color: string, percent: number): string => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt(((R * (100 + percent)) / 100).toString());
    G = parseInt(((G * (100 + percent)) / 100).toString());
    B = parseInt(((B * (100 + percent)) / 100).toString());

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (result) {
        let r = parseInt(result[1], 16);
        let g = parseInt(result[2], 16);
        let b = parseInt(result[3], 16);
        (r /= 255), (g /= 255), (b /= 255);
        const max = Math.max(r, g, b),
            min = Math.min(r, g, b);
        let h, s;
        const l = (max + min) / 2;
        if (max == min) {
            h = s = 0; // achromatic
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
                default:
                    h = 0;
            }
            h /= 6;
        }
        return {
            h,
            s,
            l,
        };
    } else {
        throw new Error('Non valid HEX color');
    }
};

export const formatNumberWithCommas = (value: number): string => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
    const postfix = rgb ? 'rgb-color' : 'color';
    switch (severity) {
        case 'error':
        case 'warning':
        case 'success':
            return `var(--${severity}-${postfix})`;
        case 'info':
        default:
            return `var(--primary-${postfix})`;
    }
};

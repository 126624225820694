import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PathInterface } from '@app/interfaces/interfaces';
import * as S from './styles.style';
import RoutePathComponent from '@app/components/common/route-path.component';
import { useLocation } from 'react-router-dom';
import { ProductDomain } from '@app/domains/product.domain';
import { CategoryDomain } from '@app/domains/category.domain';
import { ProductCategories } from '@app/api/product.api';
import { ProductCard } from '@app/components/common/product-card/produt-cart.component';
import Pagination from '@mui/material/Pagination';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import ProductFilter from '@app/components/common/filters/product-filter';
import createRoot from 'react-shadow';
import { MenuOutlined } from '@ant-design/icons';
import { BrandDomain } from '@app/domains/brand.domain';
import { GetBrands } from '@app/api/brand.api';
import { GetCategories } from '@app/api/category.api';
import { StyleSheetManager } from 'styled-components';
import { Tag } from 'antd';

const ProductCategoryPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [products, setProducts] = useState<Array<ProductDomain>>([]);
  const [category, setCategory] = useState<CategoryDomain>();
  const [count, setCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: (string | number)[] }>({
    categories: [],
    brands: [],
    price: [],
    sort: []
  });
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > 1097);
  const [isOpen, setIsOpen] = useState<boolean>(window.innerWidth > 1097);

  const fetchProducts = useCallback(
    async (currentPage: number, filters: { [key: string]: (string | number)[] }) => {
      ProductCategories(location.state.slug, currentPage, filters)
        .then((result) => {
          const { data } = result;
          setProducts(data.products);
          setCount(Math.ceil(data.count / 18)); // Assuming 18 items per page
          scroll.scrollToTop();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [location.state.slug]
  );

  useEffect(() => {
    setCategory(location.state);

    // Fetch initial products when the page loads or category changes
    fetchProducts(1, selectedFilters);
  }, [location.pathname, fetchProducts]);

  const routePaths: PathInterface[] = [
    { path: '/', title: t('home') },
    { path: '/products', title: t('products') },
    { path: '#', title: t('categories') },
    { path: '#', title: category?.title ?? '' },
  ];

  useEffect(() => {
    Events.scrollEvent.register('begin', (to: any, element: any) => {
      console.log('begin', to, element);
    });
    Events.scrollEvent.register('end', (to: any, element: any) => {
      console.log('end', to, element);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const handlePageClick = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    fetchProducts(value, selectedFilters);
  };

  const onSubmitFilters = (filters: { [key: string]: (string | number)[] }) => {
    setPage(1); // Reset to the first page when filters change
    fetchProducts(1, filters); // Fetch products with updated filters
    !isDesktop && setIsOpen(false);
  };

  const onClose = () => {
      setIsOpen(false);
    };
  
    useEffect(() => {
      window.addEventListener('resize', () => {
        setIsDesktop(window.innerWidth > 1097);
        setIsOpen(window.innerWidth > 1097);
      });
    }, []);

    useEffect(() => {
      const body = document.querySelector('body');
      if(!isDesktop && isOpen && body) {
        body.style.overflowY = 'hidden';
        body.style.height = '100vh';
      }

      return () => {
        if(!isDesktop && isOpen && body) {
          body.style.overflowY = 'auto';
          body.style.height = 'auto';
        }
      }
    }, [isDesktop, isOpen])
  
    const [brands, setBrands] = useState<BrandDomain[]>([]);
  
    const fetchBrands = useCallback(async () => {
      const data = await GetBrands();
      setBrands(data.data);
    }, []);
  
    useEffect(() => {
      fetchBrands();
    }, [fetchBrands]);
  
    const getSelectedTitles = (key: string, ids: (string | number)[]): string[] => {
      if (key === 'brands') {
        return ids
          .map((id) => brands.find((brand) => brand.id === id)?.title)
          .filter((title): title is string => !!title);
      }
      if (key === 'price') {
        return ids as string[];
      }
      if (key === 'sort') {
        if (ids.includes('a-z')) {
          return ['A-Z'];
        } else if (ids.includes('z-a')) {
          return ['Z-A'];
        } else if (ids.includes('new')) {
          return ['New products'];
        } else if (ids.includes('hot')) {
          return ['Hot products'];
        }
      }
      return [];
    };
  
    const removeFilter = (key: string, value: string | number) => {
      if (key === 'brands') {
        const id = brands.find((brand) => brand.title === value)?.id;
  
        if (id !== undefined) {
          setSelectedFilters((prev) => {
            const updatedFilters = {
              ...prev,
              [key]: prev[key].filter((item) => item !== id),
            };
            fetchProducts(1, updatedFilters); // Use the updated filters here
            return updatedFilters;
          });
        }
      } else if (key === 'price') {
        setSelectedFilters((prev) => {
          const updatedFilters = {
            ...prev,
            [key]: [],
          };
          fetchProducts(1, updatedFilters); // Use the updated filters here
          return updatedFilters;
        });
      } else if (key === 'sort') {
        setSelectedFilters((prev) => {
          const updatedFilters = {
            ...prev,
            [key]: [],
          };
          fetchProducts(1, updatedFilters); // Use the updated filters here
          return updatedFilters;
        });
      }
    };
  
    const shadowRootRef = useRef<HTMLDivElement | null>(null);

  return (
    <S.LayoutMaster>
      <div className={'box'}>
        <RoutePathComponent paths={routePaths} />

        <createRoot.div ref={shadowRootRef} style={{ height: '100%' }}>
          <style>{`@import url('https://cdnjs.cloudflare.com/ajax/libs/antd/4.24.16/antd.min.css');`}</style>
          {shadowRootRef.current?.shadowRoot && (
            <StyleSheetManager target={shadowRootRef.current.shadowRoot}>
              <S.Filters>
                <S.FiltersButton onClick={() => setIsOpen(true)}><MenuOutlined/> Filters</S.FiltersButton>
                <S.AppliedFilters>
                  {Object.entries(selectedFilters).map(([key, ids]) =>
                    getSelectedTitles(key, ids).map((title) => (
                      <Tag key={`${key}-${title}`} closable onClose={() => removeFilter(key, title)}>
                        {title}
                      </Tag>
                    )),
                  )}
                </S.AppliedFilters>
              </S.Filters>
            </StyleSheetManager>
          )}
        </createRoot.div>

        <div className={'row'}>
          <div className={'col w-30'} style={isOpen ? { display: 'block' } : { display: 'none' }}>
            <ProductFilter
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              onSubmit={onSubmitFilters}
              show={{ categories: false, brands: true, price: true, sort: true }}
              onClose={onClose}
            />
          </div>
          <div className={'col w-70'}>
            <div className={'d-flex'}>
              {products.map((product, index) => (
                <ProductCard product={product} key={index} />
              ))}
            </div>

            <Pagination count={count} color="secondary" page={page} onChange={handlePageClick} />
          </div>
        </div>
      </div>
    </S.LayoutMaster>
  );
};

export default ProductCategoryPage;

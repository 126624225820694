import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './assets/styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './router/app.router';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './assets/styles/themes/themeVariables';
import { getIp } from '@app/store/slices/ip.slice';
import { setCategories } from '@app/store/slices/category.slice';
import { GetCategories } from '@app/api/category.api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  usePWA();
  useAutoNightMode();
  useThemeWatcher();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getIp());

    GetCategories().then((result) => {
      dispatch(setCategories(result.data));
    });
  }, [dispatch]);

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <ConfigProvider locale={language === 'en' ? enUS : deDe}>
        <AppRouter />
      </ConfigProvider>
      <ToastContainer />
    </>
  );
};

export default App;

import styled from 'styled-components';

export const Slider = styled.div`
  position: relative;

  &::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
  }

  overflow: auto; /* Allows scrolling even with the scrollbar hidden */
  -ms-overflow-style: none; /* Disables scrollbar for IE 10+ */
  scrollbar-width: none; /* Disables scrollbar for Firefox */

  .slider-btns {
    position: absolute;
    right: 0;
    top: 0;

    .slider-btn {
      background: var(--porpule);
      color: var(--porpule);
      border: 2px solid var(--porpule);
      border-radius: 5px;
      padding: 5px;
      margin-left: 30px;
      cursor: pointer;
    }

    .prev-btn {
      background: transparent;
    }

    .next-btn {
      color: white;
    }
  }

  .slider {
    padding-top: 50px;
  }
`;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../styles.style';
import RoutePathComponent from '@app/components/common/route-path.component';
import { PathInterface } from '@app/interfaces/interfaces';
import { CartDomain } from '@app/domains/cart.domain';
import { CartRequest, GetCart } from '@app/api/cart.api';
import RequestOrderImage from '@app/assets/images/requestorder.png';
import { InputComponent } from '@app/components/common/input/input.component';
import { Checkbox, FormControlLabel } from '@mui/material';
import validator from 'validator';
import { FileComponent } from '@app/components/common/file/file.component';
import { TextAreaComponent } from '@app/components/common/text-area/text-area.component';
import ReCAPTCHA from 'react-google-recaptcha';
import { GetIpAddress } from '@app/api/ip.api';
import { SendInquiryCart } from '@app/api/inquiry.api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const RequestOrderPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const routePaths: PathInterface[] = [
    { path: '/', title: t('home') },
    { path: '/cart', title: 'Cart' },
    { path: '#', title: 'Request for order' },
  ];

  const [ipAddress, setIpAddress] = useState<string>('');
  const [cart, setCart] = useState<CartDomain>();

  const [createAccount, setCreateAccount] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    GetIpAddress().then((resultIp) => {
      setIpAddress(resultIp.ip);
      const requestCart: CartRequest = {
        userId: 0,
        discountId: 0,
        ipAddress: resultIp.ip,
      };
      GetCart(requestCart).then((result) => {
        setCart(result.data);

        if (!result.data || result.data.carts.length === 0) {
          navigate('/cart');
        }
      });
    });
  }, [navigate]);

  const handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmailError('');
    setEmail(value);

    if (!validator.isEmpty(value) && !validator.isEmail(value)) {
      setEmailError(t('invalid-email-address'));
    }
  };

  const handleSelectFile = (file: File | null) => {
    setFileError('');
    setFile(file);
  };

  const handleSetDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setDescriptionError('');
    setDescription(value);
  };

  function ReCAPTCHARecaptcha(value: string | null) {
    setRecaptchaValue(value);
  }

  const handleSubmitForm = () => {
    let goOn = true;

    if (validator.isEmpty(email)) {
      setEmailError(t('required-email-address'));
      goOn = false;
    } else if (!validator.isEmail(email)) {
      setEmailError(t('invalid-email-address'));
      goOn = false;
    }

    if (!recaptchaValue) {
      toast.error(t('select-recaptcha'));
      goOn = false;
    }

    if (goOn) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('email', email);
      formData.append('createAccount', createAccount.toString());
      formData.append('captchaToken', recaptchaValue ?? '');
      formData.append('description', description);
      formData.append('file', file as Blob);

      SendInquiryCart(cart?.id ?? 0, formData)
        .then((result) => {
          if (result.success) {
            toast.success(t(result.message));
            setEmail('');
            setCreateAccount(false);
            setFile(null);
            setDescription('');
            setRecaptchaValue(null);
            navigate('/cart/request-order/detail/' + result.data.uuid, {
              state: result.data,
            });
          } else {
            toast.error(t('inquiry-request-failed'));
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error(t('inquiry-request-failed'));
          setIsLoading(false);
        });
    }
  };

  return (
    <S.LayoutMaster>
      <S.RequestOrder>
        <div className={'box-route'}>
          <RoutePathComponent paths={routePaths} />
        </div>
        <div className={'header'}>
          <p>{t('send-your-request')}</p>
          <p className={'description'}>{t('send-request-description')}</p>
        </div>
        <div className={'box'}>
          <div className={'row'}>
            <p>{t('enter-email-address')}</p>

            <div className={'col'}>
              <InputComponent
                error={emailError}
                value={email}
                onChange={handleSetEmail}
                type={'email'}
                placeholder={'enter-email'}
              />
              <FormControlLabel
                control={<Checkbox checked={createAccount} onChange={() => setCreateAccount(!createAccount)} />}
                label={t('create-account-email')}
              />
            </div>
          </div>
          <div className={'row'}>
            <p>{t('upload-file-request-order')}</p>

            <div className={'col'}>
              <FileComponent
                error={fileError}
                types={'*'}
                max={2}
                onChange={(file: any) => handleSelectFile(file)}
              />
            </div>
          </div>
          <div className={'row'}>
            <p>{t('additional-description')}</p>
            <div className={'col'}>
              <TextAreaComponent
                error={descriptionError}
                value={description}
                onChange={handleSetDescription}
              />
            </div>
          </div>
          <div className={'line'}>
            <ReCAPTCHA
              sitekey="6Le8_fcoAAAAAEmIF6EO2EjKvNaAB5GstOQNnByg"
              onChange={ReCAPTCHARecaptcha}
            />

            <S.SubmitButton type="primary" htmlType="button" loading={isLoading} onClick={handleSubmitForm}>
              <span>{t('send-request')}</span>
            </S.SubmitButton>
          </div>

          <div className={'image-row'}>
            <img src={RequestOrderImage} className={'image'} />
          </div>
        </div>
      </S.RequestOrder>
    </S.LayoutMaster>
  );
};

export default RequestOrderPage;

import { ThemeType } from '@app/interfaces/interfaces';
import { css } from 'styled-components';
import { BASE_COLORS } from './constants';
import { darkColorsTheme, antDarkColorsTheme } from './dark/darkTheme';
import { lightColorsTheme } from './light/lightTheme';

export const themeObject = {
  light: lightColorsTheme,
  dark: darkColorsTheme,
};

export const antThemeObject = {
  light: {},
  dark: antDarkColorsTheme,
};

const getThemeVariables = (theme: ThemeType) => css`
  color-scheme: ${theme};
  --primary-color: ${themeObject[theme].primary};
  --nav-text-color: ${themeObject[theme].navTextColor};
  --border-base-color: ${themeObject[theme].borderBaseColor};
  --item-hover-bg: ${themeObject[theme].itemHoverBg};
  --main-link-color: ${themeObject[theme].mainLinkColor};
  --main-link-hover-color: ${themeObject[theme].mainLinkHoverColor};
  --main-link-active-color: ${themeObject[theme].mainLinkActiveColor};
  --search-icon-color: ${themeObject[theme].searchIconColor};
  --spinner-base: ${themeObject[theme].spinnerBase};
  --deactivated-link-color: ${themeObject[theme].deactivatedLinkColor};
  --porpule: ${themeObject[theme].porpule};
  --light-gray: ${themeObject[theme].lightGray};
  --yellow-color: ${themeObject[theme].yellow};
  --dark-blue-color: ${themeObject[theme].darkBlue};
  --gray-color: ${themeObject[theme].gray};
  --red-color: ${themeObject[theme].red};
  --red-light-color: ${themeObject[theme].redLight};
  --lighter-gray-color: ${themeObject[theme].lighterGray};
  --potpule-2: ${themeObject[theme].porpule2};
  --dark-porpul: ${themeObject[theme].darkPorpul};
  --light-purpol: ${themeObject[theme].lightPorpul};
  --light-green: ${themeObject[theme].lightGreen} !important;
  --dark-grey:${themeObject[theme].darkGrey};
  --scroll-color:${themeObject[theme].darkGrey};
`;

export const lightThemeVariables = css`
  ${getThemeVariables('light')}
`;

export const darkThemeVariables = css`
  ${getThemeVariables('dark')}
  --ant-success-color-deprecated-bg: ${antThemeObject['dark'].successBg} !important;
  --ant-success-color-deprecated-border: ${antThemeObject['dark'].successBorder} !important;
`;

export const commonThemeVariables = css`
  color-scheme: light dark;
  --white: ${BASE_COLORS.white};
  --black: ${BASE_COLORS.black};
  --green: ${BASE_COLORS.green};
  --orange: ${BASE_COLORS.orange};
  --gray: ${BASE_COLORS.gray};
  --lightgrey: ${BASE_COLORS.lightgrey};
  --violet: ${BASE_COLORS.violet};
  --lightgreen: ${BASE_COLORS.lightgreen};
  --pink: ${BASE_COLORS.pink};
  --blue: ${BASE_COLORS.blue};
  --skyblue: ${BASE_COLORS.skyblue};
  --red: ${BASE_COLORS.red};
`;

export const antOverrideCssVariables = css`
  --ant-primary-1: var(--primary1-color) !important;
`;

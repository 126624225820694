import {httpApi} from "@app/api/http.api";

export interface InquiryResult {
    success: boolean,
    message: string,
    code: number,
    data: {
        uuid: string
    }
}


export const SendInquiryCart = (id: number, formData: FormData): Promise<InquiryResult> =>
    httpApi.post<InquiryResult>('inquiries/create/cart/' + id, formData).then(({data}) => data);

export const SendInquiry = (formData: FormData): Promise<InquiryResult> =>
    httpApi.post<InquiryResult>('inquiries/create', formData).then(({data}) => data);
import { media } from '@app/assets/styles/themes/constants';
import { Button } from 'antd';
import styled from 'styled-components';

// Styled Components
export const FilterWrapper = styled.div`
  color: var(--black);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background: #fff;
  min-height: 700px;
  position: relative;

   @media only screen and ${media.lg} {
      height: 100%;
      border-radius: 0;
   } 

  * {
    color: var(--black);
  }

  .filters {
    height: 600px;
    overflow: auto;

    @media only screen and ${media.lg} {
      height: calc(100% - 150px);
      margin-top: 50px;
   } 
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .filters::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .filters {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .row {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 0 20px;
    width: 100%;
  }
`;

export const AppliedFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;

  @media only screen and ${media.lg} {
    display: none;
  } 
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  cursor: pointer;

  @media only screen and ${media.lg} {
    display: block;
  } 
`

export const Item = styled.div`
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  background: #eee;
  padding: 10px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
`

export const Section =  styled.div`
  overflow: hidden;
  transition: 0.3s ease;
  transform: translateY(-10px) scaleY(0);
  height: 0;
  transform-origin: 50% 0;
  background: #f3f3f3;
  position: relative;
  z-index: 0;
  border-radius: 0 0 8px 8px;

  &.active {
    padding: 10px 4px;
    transform: translateY(-10px) scaleY(1);
    height: auto;
  }
`

export const StyledButton = styled(Button)`
  margin-top: 16px;
  // padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  span {
    font-size: 15px;
    font-family: "robotoMedium" !important;
    font-weight: 400;
  }

  &.green {
    background: var(--primary-color);
    span {
      color: var(--white);
    }
  }
  
  &.white {
    background: var(--white);
    border: 1px solid var(--border-base-color);
    span {
      color: var(--primary-color);
    }
  }
`;

export const FilterTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;

  @media only screen and ${media.lg} {
    display: none;
  }
`;
import { httpApi } from "@app/api/http.api";
import { ProductDomain } from "@app/domains/product.domain";
import { BrandDomain } from "@app/domains/brand.domain";

export interface ProductResult {
    success: boolean,
    message: string,
    code: number,
    data: ProductDomain
}

export interface ProductResultArray {
    success: boolean,
    message: string,
    code: number,
    data: {
        products: Array<ProductDomain>,
        count: number
    }
}

export interface ProductResultBrand {
    success: boolean,
    message: string,
    code: number,
    data: {
        products: Array<ProductDomain>,
        brand: BrandDomain,
        count: number
    }
}

export const SingleProductDetail = (slug: string): Promise<ProductResult> =>
    httpApi.get<ProductResult>('products/detail/' + slug).then(({ data }) => data);


export const ProductCategories = async (slug: string, page: number, filters?: { [key: string]: (string | number)[] },): Promise<ProductResultArray> => {
     // Serialize filters into query parameters
     const queryFilters = filters
     ? Object.entries(filters)
         .map(([key, values]) => `${key}=${encodeURIComponent(values.join(','))}`)
         .join('&')
     : '';
 
   // Construct the URL
   const url = `products/categories/${slug}?page=${page}${queryFilters ? `&${queryFilters}` : ''}`;
 
   // Make the API call
   return await httpApi.get<ProductResultArray>(url).then(({ data }) => data);
}
    
    // httpApi.get<ProductResultArray>('products/categories/' + slug + '?page=' + page).then(({ data }) => data);

export const ProductBrand = async (slug: string, page: number, filters?: { [key: string]: (string | number)[] },): Promise<ProductResultBrand> => {
    // Serialize filters into query parameters
    const queryFilters = filters
    ? Object.entries(filters)
        .map(([key, values]) => `${key}=${encodeURIComponent(values.join(','))}`)
        .join('&')
    : '';

  // Construct the URL
  const url = `products/brand/${slug}?page=${page}${queryFilters ? `&${queryFilters}` : ''}`;

  // Make the API call
  return await httpApi.get<ProductResultBrand>(url).then(({ data }) => data);
}

export const AdvanceSearch = async (
    page: number,
    filters?: { [key: string]: (string | number)[] },
  ): Promise<ProductResultArray> => {
    // Serialize filters into query parameters
    const queryFilters = filters
      ? Object.entries(filters)
          .map(([key, values]) => `${key}=${encodeURIComponent(values.join(','))}`)
          .join('&')
      : '';
  
    // Construct the URL
    const url = `products/advance-search?page=${page}${queryFilters ? `&${queryFilters}` : ''}`;
  
    // Make the API call
    return await httpApi.get<ProductResultArray>(url).then(({ data }) => data);
  };
  

export const HotProducts = (page: number): Promise<ProductResultArray> =>
    httpApi.get<ProductResultArray>('products/hot?page=' + page).then(({ data }) => data);

export const NewProducts = (page: number): Promise<ProductResultArray> =>
    httpApi.get<ProductResultArray>('products/new?page=' + page).then(({ data }) => data);

export const SearchProduct = (title: string, multiSearch: boolean, page: number): Promise<ProductResultArray> =>
    httpApi.post<ProductResultArray>('products/search?page=' + page, { title: title, multiSearch: multiSearch }).then(({ data }) => data);


import styled from "styled-components";
import {Layout} from "antd";
import {media} from "@app/assets/styles/themes/constants";

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;
  color: var(--black);

  .box {
    width: 80%;
    margin: 0 auto 0 auto;

    @media only screen and ${media.xl} {
      width: 80%;
    }

    @media only screen and ${media.md} {
      width: 95%;
    }

    @media only screen and ${media.sm} {
      width: 95%;
    }

    @media only screen and ${media.xs} {
      width: 95%;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 5px;
      margin: 10px auto 10px auto;

      .col {
        display: block;
      }

      .d-flex {
        display: flex;
        flex-wrap: wrap;
      }
      
      .w-30 {
        width: 30% !important;

        @media only screen and ${media.lg} {
          width: 100vw !important;
          height: 100vh;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2000;
        }
      }

      .w-70 {
        width: 70% !important;

         @media only screen and ${media.lg} {
            width: 100% !important;
         }

        .d-flex {
          width: 100%;
        }
      }
      
      .black{
        color: var(--black);
        text-align: justify;
      }
    }
  }

`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const FiltersButton = styled.div`
  padding: 15px;
  cursor: pointer;
  display: none;
  font-weight: bold;

  @media only screen and ${media.lg} {
    display: block;
  }
`

export const AppliedFilters = styled.div`
  display: none;
  flex-wrap: wrap;
  gap: 8px;
  padding: 15px;

  @media only screen and ${media.lg} {
    display: flex;
  } 
`;
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Slider, Tag } from 'antd';
import * as S from './filters.style';
import { GetBrands } from '@app/api/brand.api';
import { BrandDomain } from '@app/domains/brand.domain';
import { CategoryDomain } from '@app/domains/category.domain';
import { GetCategories } from '@app/api/category.api';
import createRoot from 'react-shadow';
import { StyleSheetManager } from 'styled-components';
import { GetMinMaxPrices } from '@app/api/prices.api';
import { CloseSvg } from '@app/assets/images/svgs/close.svg';

interface Props {
  selectedFilters: { [key: string]: (string | number)[] };
  setSelectedFilters: React.Dispatch<React.SetStateAction<{ [key: string]: (string | number)[] }>>;
  onSubmit: (filters: { [key: string]: (string | number)[] }) => void;
  show: {
    categories: boolean;
    price: boolean;
    brands: boolean;
    sort: boolean;
  };
  onClose: () => void;
}

const ProductFilter = ({ selectedFilters, setSelectedFilters, onSubmit, show, onClose }: Props) => {
  const [localFilters, setLocalFilters] = useState<{ [key: string]: (string | number)[] }>(selectedFilters);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 0]);
  const [brands, setBrands] = useState<BrandDomain[]>([]);
  const [categories, setCategories] = useState<CategoryDomain[]>([]);
  const [prices, setPrices] = useState<{ minPrice: string; maxPrice: string }>();
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    categories: false,
    brands: false,
    price: false,
    sort: false,
  });
  const shadowRootRef = useRef<HTMLDivElement | null>(null);

  // Sync localFilters with selectedFilters on mount or when selectedFilters changes
  useEffect(() => {
    setLocalFilters(selectedFilters);
  }, [selectedFilters]);

  const fetchBrands = useCallback(async () => {
    const data = await GetBrands();
    setBrands(data.data);
  }, []);

  const fetchCategories = useCallback(async () => {
    const data = await GetCategories();
    setCategories(data.data);
  }, []);

  const fetchPrices = useCallback(async () => {
    const data = await GetMinMaxPrices();
    setPrices(data.data);
    setPriceRange([Number(data.data.minPrice), Number(data.data.maxPrice)]);
  }, []);

  useEffect(() => {
    fetchBrands();
    fetchCategories();
    fetchPrices();
  }, [fetchBrands, fetchCategories, fetchPrices]);

  const toggleSection = (key: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleSelect = (key: string, id: number | string, title: string) => {
    setLocalFilters((prev) => {
      const alreadySelected = prev[key].includes(id);
      const updatedIds = alreadySelected ? prev[key].filter((item) => item !== id) : [...prev[key], id];
      return {
        ...prev,
        [key]: updatedIds,
      };
    });
  };

  const handleSelectPrice = (value: string) => {
    setLocalFilters((prev) => ({
      ...prev,
      price: [value],
    }));
  };

  const handleSelectSort = (value: string) => {
    setLocalFilters((prev) => ({
      ...prev,
      sort: [value],
    }));
  };

  const removeFilter = (key: string, value: string | number) => {
    if (key === 'categories') {
      const id = categories
        .flatMap((category) => {
          const collectIds = (data: CategoryDomain[]): { id: number; title: string }[] =>
            data.flatMap((cat) => [{ id: cat.id, title: cat.title }, ...collectIds(cat.categories || [])]);
          return collectIds([category]);
        })
        .find((cat) => cat.title === value)?.id;

      if (id !== undefined) {
        setLocalFilters((prev) => ({
          ...prev,
          [key]: prev[key].filter((item) => item !== id),
        }));
      }
    } else if (key === 'brands') {
      const id = brands.find((brand) => brand.title === value)?.id;

      if (id !== undefined) {
        setLocalFilters((prev) => ({
          ...prev,
          [key]: prev[key].filter((item) => item !== id),
        }));
      }
    } else if (key === 'price') {
      setLocalFilters((prev) => ({
        ...prev,
        [key]: [],
      }));
      setPriceRange([100, 5000]);
    } else if (key === 'sort') {
      setLocalFilters((prev) => ({
        ...prev,
        [key]: [],
      }));
    }
  };

  const handleReset = () => {
    setLocalFilters({ categories: [], brands: [], price: [] });
    setSelectedFilters({ categories: [], brands: [], price: [] });
    setPriceRange([100, 5000]);
    setOpenSections({ categories: false, brands: false, price: false, sort: false });
    onSubmit({ categories: [], brands: [], price: [] });
  };

  const getSelectedTitles = (key: string, ids: (string | number)[]): string[] => {
    if (key === 'categories') {
      const findCategoryTitle = (id: number, data: CategoryDomain[]): string | undefined => {
        for (const category of data) {
          if (category.id === id) return category.title;
          if (category.categories?.length) {
            const found = findCategoryTitle(id, category.categories);
            if (found) return found;
          }
        }
        return undefined;
      };
      return ids.map((id) => findCategoryTitle(id as number, categories)).filter((title): title is string => !!title);
    }
    if (key === 'brands') {
      return ids
        .map((id) => brands.find((brand) => brand.id === id)?.title)
        .filter((title): title is string => !!title);
    }
    if (key === 'price') {
      return ids as string[];
    }
    if (key === 'sort') {
      if (ids.includes('a-z')) {
        return ['A-Z'];
      } else if (ids.includes('z-a')) {
        return ['Z-A'];
      } else if (ids.includes('new')) {
        return ['New products'];
      } else if (ids.includes('hot')) {
        return ['Hot products'];
      }
    }
    return [];
  };

  const generateCategoryList = (data: CategoryDomain[], parentKey = '') =>
    data?.map((category) => {
      const key = `${parentKey}${category.id}`;
      const isSelected = localFilters.categories.includes(category.id);
      return (
        <div key={key}>
          <S.Item onClick={() => toggleSection(key)}>
            <span onClick={() => handleSelect('categories', category.id, category.title)}>
              {category.title} {isSelected && ' (selected)'}
            </span>
            <span style={{ marginRight: '8px' }}>
              {category.categories?.length > 0 ? (openSections[key] ? 'v' : '>') : null}
            </span>
          </S.Item>
          <S.Section className={openSections[key] && category.categories?.length > 0 ? 'active' : ''}>
            <div style={{ marginLeft: '16px' }}>{generateCategoryList(category.categories, key)}</div>
          </S.Section>
        </div>
      );
    });

  return (
    <createRoot.div ref={shadowRootRef} style={{ height: '100%' }}>
      <style>{`@import url('https://cdnjs.cloudflare.com/ajax/libs/antd/4.24.16/antd.min.css');`}</style>
      {shadowRootRef.current?.shadowRoot && (
        <StyleSheetManager target={shadowRootRef.current.shadowRoot}>
          <S.FilterWrapper>
            <S.CloseButton onClick={onClose}>
              <CloseSvg />
            </S.CloseButton>
            <div className="filters">
              <S.FilterTitle>Applied filters:</S.FilterTitle>
              <S.AppliedFilters>
                {Object.entries(localFilters).map(([key, ids]) =>
                  getSelectedTitles(key, ids).map((title) => (
                    <Tag key={`${key}-${title}`} closable onClose={() => removeFilter(key, title)}>
                      {title}
                    </Tag>
                  )),
                )}
              </S.AppliedFilters>
              {/* Categories */}
              {show.categories && (
                <div>
                  <S.Item onClick={() => toggleSection('categories')}>
                    <span>Categories</span>
                    <span style={{ marginRight: '8px' }}>{openSections.categories ? 'v' : '>'}</span>
                  </S.Item>
                  <S.Section className={openSections.categories ? 'active' : ''}>
                    <div style={{ marginLeft: '16px' }}>
                      {categories.length > 0 ? generateCategoryList(categories) : null}
                    </div>
                  </S.Section>
                </div>
              )}
              {/* Brands */}
              {show.brands && (
                <div>
                  <S.Item onClick={() => toggleSection('brands')}>
                    <span>Brands</span>
                    <span style={{ marginRight: '8px' }}>{openSections.brands ? 'v' : '>'}</span>
                  </S.Item>
                  <S.Section className={openSections.brands ? 'active' : ''}>
                    {brands?.map((brand) => (
                      <S.Item key={brand.id} onClick={() => handleSelect('brands', brand.id, brand.title)}>
                        {brand.title}
                        {localFilters.brands.includes(brand.id) && ' (selected)'}
                      </S.Item>
                    ))}
                  </S.Section>
                </div>
              )}
              {/* Price */}
              {show.price && (
                <div>
                  <S.Item onClick={() => toggleSection('price')}>
                    <span>Price</span>
                    <span style={{ marginRight: '8px' }}>{openSections.price ? 'v' : '>'}</span>
                  </S.Item>
                  <S.Section className={openSections.price ? 'active' : ''}>
                    <Slider
                      range
                      min={Number(prices?.minPrice)}
                      max={Number(prices?.maxPrice)}
                      step={100}
                      value={priceRange}
                      onChange={(value) => setPriceRange(value as [number, number])}
                      onAfterChange={(value) => handleSelectPrice(`${value[0]}-${value[1]}`)}
                    />
                    <div>
                      Price: €{priceRange[0]} - €{priceRange[1]}
                    </div>
                  </S.Section>
                </div>
              )}
              {show.sort && (
                <div>
                  <S.Item onClick={() => toggleSection('sort')}>
                    <span>Sort by</span>
                    <span style={{ marginRight: '8px' }}>{openSections.sort ? 'v' : '>'}</span>
                  </S.Item>
                  <S.Section className={openSections.sort ? 'active' : ''}>
                    <S.Item key={'a-z'} onClick={() => handleSelectSort('a-z')}>
                      A-Z
                      {localFilters.sort.includes('a-z') && ' (selected)'}
                    </S.Item>
                    <S.Item key={'z-a'} onClick={() => handleSelectSort('z-a')}>
                      Z-A
                      {localFilters.sort.includes('z-a') && ' (selected)'}
                    </S.Item>
                    <S.Item key={'new'} onClick={() => handleSelectSort('new')}>
                      New products
                      {localFilters.sort.includes('new') && ' (selected)'}
                    </S.Item>
                    <S.Item key={'hot'} onClick={() => handleSelectSort('hot')}>
                      Hot products
                      {localFilters.sort.includes('hot') && ' (selected)'}
                    </S.Item>
                  </S.Section>
                </div>
              )}
            </div>
            <div className="row">
              <S.StyledButton
                size="large"
                className="green"
                onClick={() => {
                  setSelectedFilters(() => {
                    onSubmit(localFilters);
                    return localFilters;
                  });
                }}
              >
                Set Filters
              </S.StyledButton>
              <S.StyledButton size="large" className="white" onClick={handleReset}>
                Reset
              </S.StyledButton>
            </div>
          </S.FilterWrapper>
        </StyleSheetManager>
      )}
    </createRoot.div>
  );
};

export default ProductFilter;

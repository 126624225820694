import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PathInterface } from '@app/interfaces/interfaces';
import * as S from './styles.style';
import { DotMenuScg } from '@app/assets/images/svgs/dot-menu.scg';
import { LikeSvg } from '@app/assets/images/svgs/like.svg';
import { DisLikeScg } from '@app/assets/images/svgs/dis-like.scg';
import RoutePathComponent from '@app/components/common/route-path.component';
import { FavoriteSvg } from '@app/assets/images/svgs/favorite.svg';
import { ShareSvg } from '@app/assets/images/svgs/share.svg';
import { CompareSingleProductSvg } from '@app/assets/images/svgs/compare-single-product.svg';
import { Link, useLocation } from 'react-router-dom';
import { AlertSvg } from '@app/assets/images/svgs/alert.svg';
import EraTechLogo from '@app/assets/images/eratech.png';
import { PriceAlertSvg } from '@app/assets/images/svgs/price-alert.svg';
import { TicketSvg } from '@app/assets/images/svgs/ticket.svg';
import ProductSlider from '@app/components/common/product-slider';
import { PlusSvg } from '@app/assets/images/svgs/plus.svg';
import { CommentCard } from '@app/components/common/comment/comment.component';
import { ProductDomain } from '@app/domains/product.domain';
import { SingleProductDetail } from '@app/api/product.api';
import { getCurrencyPrice, getTotalPrice } from '@app/utils/utils';
import ImageSliderComponent from '@app/components/common/image-slider/image-slider.component';
import { ProductCard } from '@app/components/common/product-card/produt-cart.component';
import { AddToCart, AddToCartRequest, CartRequest, GetCart } from '@app/api/cart.api';
import { setCart } from '@app/store/slices/cart.slice';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { toast } from 'react-toastify';
import { readToken } from '@app/utils/local-storage.service';
import { UserAccountWishListStore } from '@app/api/user-account/wish-list.api';
import * as process from 'process';
import { QuestionCard } from '@app/components/common/question/question.component';
import VideoSlider from '@app/components/common/video-slider/video-slider.component';
import wordIcon from '@app/assets/images/svgs/word.svg';
import excelIcon from '@app/assets/images/svgs/excel.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pdfIcon from '@app/assets/images/svgs/pdf.svg';
import docIcon from '@app/assets/images/svgs/file-document.svg';

const SingleProductPage: React.FC = (props) => {
  const { t } = useTranslation();
  const [product, setProduct] = useState<ProductDomain>();
  const location = useLocation();
  const refTop = useRef(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [inWishList, setInWishList] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const slug = location.pathname.split('/')[2];

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    SingleProductDetail(slug).then((result) => {
      if (result.success) {
        setProduct(result.data);
        setInWishList(result.data.inWishList);
      } else {
        toast.error(t(result.message));
      }

      setLoading(false);
    });
  }, [location.pathname, inWishList]);

  const [quantity, setQuantity] = useState<number>(1);

  const routePaths: PathInterface[] = [
    { path: '/', title: t('home') },
    { path: '/products', title: t('products') },
    { path: '#', title: product?.title ?? '' },
  ];

  const refGalleries = useRef(null);
  const refFiles = useRef(null);
  const refDescription = useRef(null);
  const refAttribute = useRef(null);
  const refRelativeProduct = useRef(null);
  const refAccessoriesProduct = useRef(null);
  const refAlternativeProduct = useRef(null);
  const refComment = useRef(null);
  const refQA = useRef(null);
  const [activeSection, setActiveSection] = useState<string>('detail');
  const scrollToSection = (section: string) => {
    setActiveSection(section);
    if (section === 'galleries') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refGalleries.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (section === 'files') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refFiles.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (section === 'description') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refDescription.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (section === 'attributes') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refAttribute.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (section === 'relative-products') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refRelativeProduct.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (section === 'accessories-products') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refAccessoriesProduct.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (section === 'alternative-products') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refAlternativeProduct.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (section === 'comments') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refComment.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (section === 'q-a') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refQA.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleIncreaseQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
    }
  };

  const ipAddress = useAppSelector((state) => state.ip);

  const handleAddToCard = () => {
    setLoading(true);
    const requestCart: CartRequest = {
      userId: 0,
      discountId: 0,
      ipAddress: ipAddress.ip,
    };

    const p = product;
    GetCart(requestCart).then((result) => {
      setLoading(true);
      setCart(result.data);

      const request: AddToCartRequest = {
        priceId: p?.regularPrice.id ?? 0,
        quantity: quantity,
        unitPrice: p?.regularPrice.price ?? 0,
        cartId: result.data ? result.data.id : 0,
        userId: 0,
        ipAddress: ipAddress.ip,
        status: p?.regularPrice.stockStatus ?? '',
      };

      AddToCart(request).then((result) => {
        if (result.success) {
          toast.success(t('add-to-cart-done'));
        } else {
          toast.error(t(result.message));
        }

        setLoading(false);
      });
    });
  };

  const handleSetWishlist = () => {
    if (readToken() !== '') {
      setLoading(true);
      UserAccountWishListStore(product?.id ?? 0)
        .then((result) => {
          if (result.success) {
            toast.success(t(result.message));
            setInWishList(result.data.inWishList);
          } else {
            alert(result.message);
            toast.error(t('add-to-wish-list-failed'));
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error(error);
          setLoading(false);
        });
    } else {
      toast.error(t('login-account'));
    }
  };

  const shareProduct = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_URL + '/products/' + product?.slug);
    toast.success('product-link-copied');
  };

  return (
    <S.LayoutMaster ref={refTop}>
      <div className={'box'}>
        <RoutePathComponent paths={routePaths} />

        <div className={'row'}>
          <S.Thumbnail>
            <div className={'icons'}>
              {inWishList ? (
                <div className={'fill'} onClick={handleSetWishlist}>
                  <FavoriteSvg />
                </div>
              ) : (
                <div className={'stroke'} onClick={handleSetWishlist}>
                  <FavoriteSvg />
                </div>
              )}

              <div className={'all'} onClick={shareProduct}>
                <ShareSvg />
              </div>
              <div className={'all'}>
                <CompareSingleProductSvg />
              </div>
            </div>
            <div className={'image'}>
              <img src={product?.thumbnail} />
            </div>
          </S.Thumbnail>

          <S.Info>
            <div className={'categories'}>
              {product?.categories.map((category, index) => (
                <Link to={`/product-category/${category.slug}`} state={category} key={index}>
                  {category.title}
                </Link>
              ))}
            </div>

            <h1 className={'product-title'}>{product?.title}</h1>
            <p
              className={'product-short-description'}
              dangerouslySetInnerHTML={{ __html: product ? product.shortDescription : '' }}
            ></p>

            <div className={'short-info'} style={{ marginTop: '20px' }}>
              <p>{t('availability')}</p>
              <p>{t(product ? product.regularPrice.stockStatus : '')}</p>
            </div>
            <div className={'short-info'}>
              <p>{t('brand')}</p>
              <p>{product?.brand.title}</p>
            </div>
            <div className={'short-info'}>
              <p>{t('budget-price')}</p>
              <p>{getCurrencyPrice(product?.regularPrice.price, product?.regularPrice.country.currency)}</p>
            </div>
            <div className={'short-info'}>
              <p>{t('SKU')}</p>
              <p>{product?.sku}</p>
            </div>

            <p className={'alert'}>
              <b>
                <AlertSvg />
              </b>
              {t('price-alert-message')}
            </p>
          </S.Info>

          <S.Basket>
            <div className={'alert'}>
              <p dangerouslySetInnerHTML={{ __html: t('greenia-not-authorized-distributer') }}></p>
            </div>
            <div className={'seller'}>
              <div className="seller-row">
                <div className="col col1">
                  <p className={'title'}>{t('seller')}</p>
                  <img className={'logo'} src={EraTechLogo} />
                </div>
                <div className="col col2">
                  <div className={'price-row'}>
                    <div className={'add-to-card'}>
                      <span className={'pointer'} onClick={handleDecreaseQuantity}>
                        -
                      </span>
                      <span>{quantity}</span>
                      <span className={'pointer'} onClick={handleIncreaseQuantity}>
                        +
                      </span>
                    </div>
                    <div className={'price'}>
                      <p>{t('total-price')} :</p>
                      <strong>
                        {getTotalPrice(product?.regularPrice.price, product?.regularPrice.country.currency, quantity)}
                      </strong>
                    </div>
                  </div>
                  <S.SubmitButton type="primary" htmlType="button" loading={loading} onClick={handleAddToCard}>
                    <span>{t('order-now')}</span>
                  </S.SubmitButton>
                </div>
              </div>

              <div className={'alert-row'}>
                <div className={'p-red'}>
                  <b>
                    <AlertSvg />
                  </b>
                  <p>{t('spotted-an-error')}</p>
                </div>
                <div className={'p-black'}>
                  <b>
                    <PriceAlertSvg />
                  </b>
                  <p>{t('price-alert')}</p>
                </div>
                <div className={'p-black'}>
                  <b>
                    <TicketSvg />
                  </b>
                  <p>{t('send-ticket')}</p>
                </div>
              </div>
            </div>
          </S.Basket>
        </div>
      </div>

      <S.Tabs>
        <div className={'box'}>
          {Object.keys(product?.description ?? {}).length !== 0 ? (
            <div
              className={`item ${activeSection === 'detail' ? 'active' : ''}`}
              onClick={() => scrollToSection('detail')}
            >
              <p className={'title'}>{t('detail')}</p>
            </div>
          ) : null}
          {Object.keys(product?.attributes ?? {}).length !== 0 ? (
            <div
              className={`item ${activeSection === 'attributes' ? 'active' : ''}`}
              onClick={() => scrollToSection('attributes')}
            >
              <p className={'title'}>{t('attributes')}</p>
            </div>
          ) : null}
          {Object.keys(product?.galleries ?? {}).length !== 0 || Object.keys(product?.videos ?? {}).length !== 0 ? (
            <div
              className={`item ${activeSection === 'galleries' ? 'active' : ''}`}
              onClick={() => scrollToSection('galleries')}
            >
              <p className={'title'}>{t('galleries')}</p>
            </div>
          ) : null}
          {Object.keys(product?.files ?? {}).length !== 0 ? (
            <div
              className={`item ${activeSection === 'files' ? 'active' : ''}`}
              onClick={() => scrollToSection('files')}
            >
              <p className={'title'}>{t('files')}</p>
            </div>
          ) : null}
          {Object.keys(product?.relatives ?? {}).length !== 0 ? (
            <div
              className={`item ${activeSection === 'relative-products' ? 'active' : ''}`}
              onClick={() => scrollToSection('relative-products')}
            >
              <p className={'title'}>{t('relative-products')}</p>
            </div>
          ) : null}
          {Object.keys(product?.accessories ?? {}).length !== 0 ? (
            <div
              className={`item ${activeSection === 'accessories-products' ? 'active' : ''}`}
              onClick={() => scrollToSection('accessories-products')}
            >
              <p className={'title'}>{t('accessories')}</p>
            </div>
          ) : null}
          {Object.keys(product?.alternatives ?? {}).length !== 0 ? (
            <div
              className={`item ${activeSection === 'alternative-products' ? 'active' : ''}`}
              onClick={() => scrollToSection('alternative-products')}
            >
              <p className={'title'}>{t('alternative-products')}</p>
            </div>
          ) : null}
          <div
            className={`item ${activeSection === 'comments' ? 'active' : ''}`}
            onClick={() => scrollToSection('comments')}
          >
            <p className={'title'}>{t('comments')}</p>
          </div>
          <div className={`item ${activeSection === 'q-a' ? 'active' : ''}`} onClick={() => scrollToSection('q-a')}>
            <p className={'title'}>{t('q-a')}</p>
          </div>
        </div>
      </S.Tabs>

      {Object.keys(product?.description ?? {}).length !== 0 ? (
        <S.TabView ref={refDescription}>
          <div className={'title'}>
            <div className={'green-box'}></div>
            <p>{t('detail')}</p>
          </div>
          <div className={'box'} dangerouslySetInnerHTML={{ __html: product ? product.description : '' }}></div>
        </S.TabView>
      ) : null}

      {Object.keys(product?.attributes ?? {}).length !== 0 ? (
        <S.TabView ref={refAttribute}>
          <div className={'title'}>
            <div className={'green-box'}></div>
            <p>{t('attributes')}</p>
          </div>
          <div className={'box-table'}>
            {product?.attributes.map((mainAttribute, index) => (
              <Fragment key={mainAttribute.id}>
                <h3 className="main-attribute">{mainAttribute.title}</h3>
                <table>
                  {mainAttribute.items?.map((attribute, _index) => (
                    <Fragment key={attribute.id}>
                      <thead>
                        <th>
                          <td>
                            {attribute.title} <span>{attribute.value}</span>
                          </td>
                        </th>
                      </thead>
                      <tbody>
                        {attribute.items.map((subAttribute, __index) => (
                          <Fragment key={subAttribute.id}>
                            {subAttribute?.value && (
                              <tr>
                                <td>{subAttribute.title}</td>
                                <td>{subAttribute.value}</td>
                              </tr>
                            )}
                          </Fragment>
                        ))}
                      </tbody>
                    </Fragment>
                  ))}
                </table>
              </Fragment>
            ))}
          </div>
        </S.TabView>
      ) : null}

      {Object.keys(product?.galleries ?? {}).length !== 0 || Object.keys(product?.videos ?? {}).length !== 0 ? (
        <S.TabView ref={refGalleries}>
          <div className={'title'}>
            <div className={'green-box'}></div>
            <p>{t('galleries')}</p>
          </div>
          <div className={'box'}>
            <div className={'row gallery'}>
              {/* Image Slider */}
              {Object.keys(product?.galleries ?? {}).length !== 0 ? (
                <div className={'image-gallery'}>
                  <ImageSliderComponent images={product ? product?.galleries : []} />
                </div>
              ) : null}
              {/* Video Slider */}
              {Object.keys(product?.videos ?? {}).length !== 0 ? (
                <div className={'video-gallery'}>
                  <VideoSlider videos={product ? product?.videos : []} />
                </div>
              ) : null}
            </div>
          </div>
        </S.TabView>
      ) : null}

      {Object.keys(product?.files ?? {}).length !== 0 ? (
        <S.TabView ref={refFiles}>
          <div className={'title'}>
            <div className={'green-box'}></div>
            <p>{t('files')}</p>
          </div>
          <div className={'box'}>
            <div className={'row files-section'}>
              {product?.files.map((file) => {
                const filePath = file.file ?? ''; // Ensure it's at least an empty string
                const fileExtension = filePath.split('.').pop()?.toLowerCase(); // Safely get the file extension
                let iconSrc;

                // Match file extensions with specific icons
                switch (fileExtension) {
                  case 'pdf':
                    iconSrc = pdfIcon; // Path to your PDF icon
                    break;
                  case 'doc':
                  case 'docx':
                    iconSrc = wordIcon; // Path to your Word icon
                    break;
                  case 'xls':
                  case 'xlsx':
                    iconSrc = excelIcon; // Path to your Excel icon
                    break;
                  default:
                    iconSrc = docIcon; // Path to a default icon
                }

                return (
                  <a className="files" href={file.file} download key={file.title}>
                    <b>
                      <img src={iconSrc} alt={`${fileExtension} icon`} width={24} height={24} />
                    </b>
                    <span>{file.title}</span>
                  </a>
                );
              })}
            </div>
          </div>
        </S.TabView>
      ) : null}

      {Object.keys(product?.relatives ?? {}).length !== 0 ? (
        <S.TabView ref={refRelativeProduct}>
          <div className={'title-2'}>
            <div className={'green-box'}></div>
            <p>{t('relative-products')}</p>
          </div>
          <div className={'box'} style={{ overflow: 'scroll' }}>
            <ProductSlider options={{ align: 'center' }}>
              {product?.relatives.map((_product, _index) => (
                <ProductCard product={_product} key={_index} />
              ))}
            </ProductSlider>
          </div>
        </S.TabView>
      ) : null}

      {Object.keys(product?.accessories ?? {}).length !== 0 ? (
        <S.TabView ref={refAccessoriesProduct}>
          <div className={'title-2'}>
            <div className={'green-box'}></div>
            <p>{t('accessories')}</p>
          </div>
          <div className={'box'} style={{ overflow: 'scroll' }}>
            <ProductSlider options={{ align: 'center' }}>
              {product?.accessories.map((_product, _index) => (
                <ProductCard product={_product} key={_index} />
              ))}
            </ProductSlider>
          </div>
        </S.TabView>
      ) : null}

      {Object.keys(product?.alternatives ?? {}).length !== 0 ? (
        <S.TabView ref={refAlternativeProduct}>
          <div className={'title-2'}>
            <div className={'green-box'}></div>
            <p>{t('alternative-products')}</p>
          </div>
          <div className={'box'} style={{ overflow: 'scroll' }}>
            <ProductSlider options={{ align: 'center' }}>
              {product?.alternatives.map((_product, _index) => (
                <ProductCard product={_product} key={_index} />
              ))}
            </ProductSlider>
          </div>
        </S.TabView>
      ) : null}

      <S.TabView ref={refComment}>
        <div className={'title-3'}>
          <div className={'row'}>
            <div className={'green-box'}></div>
            <p>{t('comments')}</p>
          </div>
          <button className={'add-comment'}>
            <b>
              <PlusSvg />
            </b>
            <span>{t('new-comment')}</span>
          </button>
        </div>
        <div className={'box-comments'}>
          {product?.comments
            .filter((item) => item.status === 'confirmed')
            .map((comment, index) => (
              <CommentCard comment={comment} key={index} />
            ))}

          {/* <Link to={'/'} className={'show-more'}>
                        <a>
                            <b>
                                <PlusSvg />
                            </b>
                            <span>{t('show-more')}</span>
                        </a>
                    </Link> */}
        </div>
      </S.TabView>

      <S.TabView ref={refQA}>
        <div className={'title-3'}>
          <div className={'row'}>
            <div className={'green-box'}></div>
            <p>{t('Q&A')}</p>
          </div>
          <button className={'add-comment'}>
            <b>
              <PlusSvg />
            </b>
            <span>{t('Ask a question')}</span>
          </button>
        </div>
        <div className={'box-comments'}>
          {product?.questions
            .filter((item) => item.status === 'confirmed')
            .map((question, index) => (
              <QuestionCard question={question} key={index} />
            ))}
          {/*<Link to={'/'} className={'show-more'}>*/}
          {/*    <a>*/}
          {/*        <b>*/}
          {/*            <PlusSvg/>*/}
          {/*        </b>*/}
          {/*        <span>{t('show-more')}</span>*/}
          {/*    </a>*/}
          {/*</Link>*/}
        </div>
      </S.TabView>
    </S.LayoutMaster>
  );
};

export default SingleProductPage;

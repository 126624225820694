import {httpApi} from "@app/api/http.api";

export interface MinMaxPricesResult {
    success: boolean,
    message: string,
    code: number,
    data: { minPrice: string, maxPrice: string },
}


export const GetMinMaxPrices = (): Promise<MinMaxPricesResult> =>
    httpApi.get<MinMaxPricesResult>('home/min-max-prices').then(({data}) => data);